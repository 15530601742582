<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner m-0">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            class="img-fluid"
            :src="appLogoImage"
            alt="logo"
          />
        </b-link>
        <b-card-title
          title-tag="h2"
          class="font-weight-bold mb-1"
        >
          Forgot your password?
        </b-card-title>
        <b-card-text class="mb-2">
          We will send a reset code to your recovery email to reset your password
        </b-card-text>
        <validation-observer ref="recoveryValidation">
          <b-form
            class="auth-recovery-form mt-2"
            @submit.prevent
          >
            <b-form-group
              label="Email"
              label-for="login-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="form.email"
                  :state="errors.length > 0 ? false:null"
                  name="login-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isRecoveyLoading"
              @click="validationForm"
            >
              <b-spinner
                v-if="isRecoveyLoading"
                label="Spinning"
                small
              />
              <span v-else>
                Email me a recovery link
              </span>
            </b-button>
            <p class="mt-3">
              <b-link :to="{name:'login'}">
                Back to sign-in
              </b-link>
            </p>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BCardText, BCardTitle, BImg, BForm, BButton, BSpinner, BCard,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { toastSuccess, toastFailure } from '@/libs/toastification'
import { mapActions } from 'vuex'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BCard,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appLogoImage } = $themeConfig.app
    return {
      status: '',
      form: {
        email: '',
      },
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isRecoveyLoading: false,
      appLogoImage,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions({
      recovery: 'auth/recovery',
    }),
    async submit() {
      await this.recovery(this.form)
        .then(() => {
          toastSuccess('Recovery link has been send.')

          this.$router.replace({ name: 'login' })
        })
        .catch(() => {
          toastFailure('The given data was invalid.')
        })
        .finally(() => {
          this.isRecoveyLoading = false
        })
    },
    validationForm() {
      this.$refs.recoveryValidation.validate().then(success => {
        if (success) {
          this.isRecoveyLoading = true
          this.submit()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
